import {
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    InputText,
    Notifications,
    ObjectUtils,
    StringUtils,
} from "d-react-components";
import { filter, find, includes, map, uniqBy } from "lodash";
import React, { useContext, useMemo, useRef, useState } from "react";
import InputTextQuantity from "../../../common/input/InputTextQuantity";
import { PRODUCT_TYPE } from "../../../constant/product";
import { OrderCreateContext } from "../../../context/order";
import { IProduct } from "../../../interfaces/product";
import Messages from "../../../languages/Messages";
import ProductNameView from "../../product/common/ProductNameView";
import ProductSearchButton from "../../product/common/ProductSearchButton";
import OrderCreateCustomItemModal from "./OrderCreateCustomItemModal";
import OrderCreateDiscount from "./OrderCreateDiscount";
import OrderCreatePrice from "./OrderCreatePrice";

function OrderCreateProducts() {
    const { orderForm } = useContext(OrderCreateContext);
    const productRef = useRef<any>(null);
    const [openCustomItem, setOpenCustomItem] = useState({
        open: false,
        default: null,
    });

    const renderProductInfo = (product: any) => {
        let subContent = <div />;
        let subName = <div />;
        if (product?.isProductWorksheet) {
            subContent = (
                <small className="text-warning">
                    {`${Messages.worksheet} #${product.worksheetNo}`}
                </small>
            );
        }

        if (product?.isCustomProduct) {
            subContent = (
                <small className="text-warning">{product?.remark}</small>
            );

            subName = (
                <div
                    className="text-white bg-dark px-3 small cursor-default"
                    onClick={() =>
                        setOpenCustomItem({ open: true, default: product })
                    }
                >
                    {Messages.update}
                </div>
            );
        }
        return (
            <ProductNameView
                product={product}
                subContent={subContent}
                subName={subName}
            />
        );
    };

    const renderSalePriceInput = (
        salePrice: any,
        product: IProduct & { isCustomProduct: boolean }
    ) => {
        return (
            <InputText
                value={salePrice.toLocaleString()}
                onChange={(event) => {
                    const result = event.target.value.replace(/\D/g, "");
                    let value = parseInt(result, 10);
                    if (!value) {
                        value = 0;
                    }
                    onUpdateProduct({
                        ...product,
                        salePrice: value,
                    });
                }}
            />
        );
        // if (product?.productType === PRODUCT_TYPE?.PREMIUM_SERVICE) {
        //     return (
        //         <InputText
        //             value={salePrice.toLocaleString()}
        //             onChange={(event) => {
        //                 const result = event.target.value.replace(/\D/g, "");
        //                 let value = parseInt(result, 10);
        //                 if (!value) {
        //                     value = 0;
        //                 }
        //                 onUpdateProduct({
        //                     ...product,
        //                     salePrice: value,
        //                 });
        //             }}
        //         />
        //     );
        // }
        // return StringUtils.moneyThaiFormat(salePrice);
    };

    const columns: IColumnsProps = [
        {
            title: Messages.itemName,
            dataIndex: "",
            render: renderProductInfo,
            width: 300,
        },
        {
            title: Messages.regularPrice,
            dataIndex: "regularPrice",
            render: StringUtils.moneyThaiFormat,
        },
        {
            title: Messages.salePrice,
            dataIndex: "salePriceAtCreated",
            render: StringUtils.moneyThaiFormat,
        },
        {
            title: Messages.openingPrice,
            dataIndex: "salePrice",
            render: renderSalePriceInput,
        },
        {
            title: Messages.qnty,
            dataIndex: "quantity",
            render: (quantity, item) => (
                <InputTextQuantity
                    value={quantity}
                    onChange={(value) =>
                        onUpdateProduct({ ...item, quantity: value })
                    }
                    min={0}
                    max={item?.maxQuantity ? item?.maxQuantity : 10000}
                />
            ),
            width: 200,
        },
        {
            title: Messages.total,
            dataIndex: "",
            render: (item) =>
                StringUtils.moneyThaiFormat(item?.quantity * item?.salePrice),
        },
        {
            title: "",
            dataIndex: "",
            render: (item) => (
                <Button
                    iconName="delete"
                    variant="trans"
                    onClick={() => onClickDeleteProduct(item)}
                />
            ),
        },
    ];

    const {
        products,
        productsWorksheet,
        customProducts,
        isInvoice,
        warehouseId,
    } = orderForm?.values;

    const productDataSource = useMemo(() => {
        return [...productsWorksheet, ...products, ...customProducts];
    }, [products, productsWorksheet, customProducts]);

    const onClickDeleteProduct = (product: any) => {
        if (product.isProductWorksheet) {
            onDeleteProductWorksheet(product);
        } else if (product.isCustomProduct) {
            onDeleteCustomProduct(product?.id);
        } else {
            onDeleteDefaultProduct(product?.id);
        }
    };

    const onUpdateProduct = (newProduct: any) => {
        if (newProduct.isProductWorksheet) {
            onUpdateProductWorksheet(newProduct);
        } else if (newProduct.isCustomProduct) {
            onUpdateCustomProduct(newProduct);
        } else {
            onUpdateDefaultProduct(newProduct);
        }
    };

    /*********************************** PRODUCT CONTROL ***************************************/
    const onUpdateDefaultProduct = (newProduct: IProduct) => {
        const newValue = ObjectUtils.updateArrayById(
            products as any,
            newProduct
        );
        onUpdateProducts(newValue);
    };

    const onDeleteDefaultProduct = (id: string) => {
        const newValue = ObjectUtils.removeArrayById(products, id);
        onUpdateProducts(newValue);
    };

    const onSaveProductFromSearch = (products: any[]) => {
        /**
         * with the new product added => set default quantity = 1
         */
        const productList = map(products, (product) => {
            const existedProduct = find(
                orderForm.values.products,
                (item) => item.id === product.id
            );
            if (existedProduct) {
                return existedProduct;
            }
            return {
                ...product,
                salePriceAtCreated: product?.salePrice,
                quantity: 1,
            };
        });
        /**
         * with premium product, salePrice's default will be Reg. Price
         */
        const productPremiumService = map(productList, (product) => {
            if (product.productType === PRODUCT_TYPE.PREMIUM_SERVICE) {
                return {
                    ...product,
                    salePriceAtCreated: product?.regularPrice,
                    salePrice: product.regularPrice,
                };
            }
            return product;
        });
        /**
         * with checked invoice, service product will be price * 1.07
         */
        const productAfterInvoice = map(productPremiumService, (product) => {
            if (
                isInvoice &&
                product.productType === PRODUCT_TYPE.SERVICE_PRODUCT
            ) {
                return { ...product, salePrice: product.salePrice * 1.07 };
            }
            return product;
        });

        onUpdateProducts(productAfterInvoice);
    };

    const onUpdateProducts = (newProducts: IProduct[]) => {
        orderForm.setFieldValue("products", newProducts);
    };

    const onUpdateProductWorksheet = (newProduct: any) => {
        const newValue = map(productsWorksheet, (item) => {
            if (
                item.id + item.worksheetId ===
                newProduct.id + newProduct.worksheetId
            ) {
                return newProduct;
            }
            return item;
        });
        onUpdateProductsWorksheet(newValue);
    };

    const onDeleteProductWorksheet = (product: any) => {
        const newValue = filter(
            productsWorksheet,
            (item) =>
                item.id + item.worksheetId !== product.id + product.worksheetId
        );
        onUpdateProductsWorksheet(newValue);
    };

    const onUpdateProductsWorksheet = (newProducts: IProduct[]) => {
        orderForm.setFieldValue("productsWorksheet", newProducts);
    };

    const onAddCustomProduct = (newProduct: any) => {
        const result = uniqBy(
            [newProduct, ...customProducts],
            (item) => item.id
        );
        onUpdateCustomProducts(result);
    };

    const onUpdateCustomProduct = (newProduct: IProduct) => {
        const newValue = ObjectUtils.updateArrayById(
            customProducts as any,
            newProduct
        );
        onUpdateCustomProducts(newValue);
    };

    const onDeleteCustomProduct = (id: string) => {
        const newValue = ObjectUtils.removeArrayById(customProducts, id);
        onUpdateCustomProducts(newValue);
    };

    const onUpdateCustomProducts = (newProducts: IProduct[]) => {
        orderForm.setFieldValue("customProducts", newProducts);
    };

    const onValidateOpenSearch = () => {
        if (!warehouseId) {
            Notifications.showError(Messages.pleaseSelectWarehouseFirst);
            return true;
        }
        return false;
    };

    return (
        <div className="card-container p-4">
            <div className="d-flex justify-content-between align-items-center">
                <text className="label">{Messages.cart}</text>
                <div className="d-flex">
                    <Button
                        onClick={() =>
                            setOpenCustomItem({ open: true, default: null })
                        }
                        color="dark"
                        iconName="settings"
                        className="mr-3"
                    >
                        {Messages.addCustomItem}
                    </Button>
                    <ProductSearchButton
                        onSave={onSaveProductFromSearch}
                        defaultValue={products}
                        isInvalidateOpenSearch={onValidateOpenSearch}
                        filterSource={{ warehouseId, visibility: true }}
                    />
                </div>
            </div>
            <AwesomeTableComponent
                ref={(ref) => {
                    productRef.current = ref;
                }}
                dataSource={productDataSource}
                columns={columns}
                className="mt-3"
                baseColumnProps={{ width: 100 }}
                bordered={false}
                pagination={false}
                rowKey={(item) => `${item.id} ${item.worksheetId}`}
            />
            <div className="d-flex justify-content-between mt-3">
                <OrderCreateDiscount />
                <OrderCreatePrice />
            </div>
            {openCustomItem.open && (
                <OrderCreateCustomItemModal
                    open={openCustomItem.open}
                    onClose={() =>
                        setOpenCustomItem({
                            open: false,
                            default: null,
                        })
                    }
                    onSave={onAddCustomProduct}
                    defaultValue={openCustomItem.default}
                />
            )}
        </div>
    );
}

export default OrderCreateProducts;
