import { StringUtils } from "d-react-components";
import { filter, forEach, groupBy, isEmpty, map, reduce, toString } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { DELIVERY_STATUS } from "../constant/delivery";
import { DISCOUNT_AMOUNT_TYPE } from "../constant/discount";
import { OrderDetailContext } from "../context/order";
import {
    IOrderDiscountDisplay,
    IOrderVoucherDisplay,
} from "../interfaces/order";
import Messages from "../languages/Messages";

export interface IOrderPrice {
    subtotal: number;
    total: number;
    discountList: IOrderDiscountDisplay[];
    voucherList: IOrderVoucherDisplay[];
}

export const useOrderPrice = (order: any): IOrderPrice => {
    const [orderPrice, setOrderPrice] = useState<IOrderPrice>({
        subtotal: 0,
        total: 0,
        discountList: [],
        voucherList: [],
    });
    const {
        customProducts,
        productsWorksheet,
        products,
        vouchers,
        manualDiscounts,
    } = order;
    const allProductList = [
        ...customProducts,
        ...productsWorksheet,
        ...products,
    ];

    const allDiscountList = [...vouchers, ...manualDiscounts];

    const getSubtotal = () =>
        reduce(
            allProductList,
            (sum, product) => sum + product.salePrice * product.quantity,
            0
        );

    useEffect(() => {
        const subtotal = getSubtotal();
        onChangeOrderPrice({ subtotal });
    }, [customProducts, productsWorksheet, products]);

    useEffect(() => {
        let afterDiscount = getSubtotal();
        const discountList: IOrderDiscountDisplay[] = map(
            allDiscountList,
            (discount) => {
                const { amountType, amount } = discount;
                let discountMoney = parseFloat(amount);
                let subTitle;
                const title = Messages.manualDiscount;
                if (amountType === DISCOUNT_AMOUNT_TYPE.PERCENTAGE) {
                    discountMoney = (afterDiscount * parseFloat(amount)) / 100;
                    subTitle = `${amount}%`;
                }
                afterDiscount -= discountMoney;
                return { ...discount, total: discountMoney, subTitle, title };
            }
        );
        onChangeOrderPrice({ discountList });
    }, [JSON.stringify(manualDiscounts), orderPrice.subtotal]);

    useEffect(() => {
        const listDisplay: IOrderVoucherDisplay[] = [];
        if (!isEmpty(vouchers)) {
            const groupedVouchers = groupBy(vouchers, (i) => i?.appliedFor);
            forEach(groupedVouchers, (items, key) => {
                let numberOfVouchers = 0;
                let totalDiscountOfVouchers = 0;
                forEach(items, (voucherItem) => {
                    const { product, amount } = voucherItem || {};
                    numberOfVouchers += 1;
                    totalDiscountOfVouchers += amount;
                    return undefined;
                });
                listDisplay.push({
                    id: StringUtils.getUniqueID(),
                    appliedVouchers: items,
                    title: Messages.voucher,
                    numberOfVouchers,
                    total: totalDiscountOfVouchers,
                    appliedFor: key,
                });
            });
        }
        onChangeOrderPrice({ voucherList: listDisplay });
    }, [JSON.stringify(vouchers)]);

    useEffect(() => {
        const totalDiscount = reduce(
            orderPrice.discountList,
            (sum, discount) => sum + discount.total,
            0
        );
        const total = getSubtotal() - totalDiscount;
        onChangeOrderPrice({ total });
    }, [orderPrice.subtotal, orderPrice.discountList]);

    const onChangeOrderPrice = (newPrice = {}) => {
        setOrderPrice({ ...orderPrice, ...newPrice });
    };

    return orderPrice;
};

export const useOrderDeliveryShippedQty = (
    deliveryId: string,
    product: any
) => {
    const { orderDetail } = useContext(OrderDetailContext);
    const { deliveries = [] } = orderDetail;

    const otherDeliveries = useMemo(
        () =>
            filter(
                deliveries,
                (item) =>
                    item.id !== deliveryId &&
                    item.status !== DELIVERY_STATUS.CANCEL
            ),
        [deliveryId]
    );

    const shippedQty = useMemo(() => {
        let quantity = 0;
        forEach(otherDeliveries, (delivery) => {
            forEach(delivery?.products ?? [], (pro) => {
                if (pro.id === product.id) {
                    quantity += pro?.shippingQty;
                }
            });
        });
        return quantity;
    }, [otherDeliveries]);
    return shippedQty;
};
