import {
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    InputText,
    Modal,
    Notifications,
    Progress,
    StringUtils,
} from "d-react-components";
import { useFormik } from "formik";
import { filter, find, includes, map } from "lodash";
import React, { useContext, useState } from "react";
import VoucherAPI from "../../../api/voucher/VoucherAPI";
import {
    DISCOUNT_AMOUNT_TYPES,
    DISCOUNT_TYPE,
} from "../../../constant/discount";
import { PRODUCT_TYPE } from "../../../constant/product";
import { OrderCreateContext } from "../../../context/order";
import { useAppliedVouchersProductList } from "../../../hoook/voucher";
import { IProduct } from "../../../interfaces/product";
import {
    isAnonymousVoucher,
    IVoucher,
    mapVoucherToVoucherOrderCreate,
} from "../../../interfaces/voucher";
import Messages from "../../../languages/Messages";
import ProductNameView from "../../product/common/ProductNameView";
import { addIdAndAmountToListVoucher } from "./vouchers/OrderCreateSelectVoucherButton";
import VoucherSelectDrawer, {
    VoucherSelectScreenType,
} from "./vouchers/VoucherSelectDrawer";

interface IOrderCreateDiscountModal {
    open: boolean;
    onClose: () => void;
}

const OrderConfirmVoucherModal = ({
    open,
    onClose,
    products = [],
    onSave,
}: any) => {
    const [productSelect, setProductSelect] = useState<IProduct>();

    const columns: IColumnsProps = [
        {
            title: Messages.itemName,
            dataIndex: "",
            render: (product: IProduct) => (
                <ProductNameView product={product} />
            ),
        },
    ];
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.applyVoucherConfirmation}
            onSave={() => onSave(productSelect)}
        >
            <AwesomeTableComponent
                columns={columns}
                dataSource={products}
                rowSelection={{
                    type: "radio",
                    onChange: (selectedRowKeys, [selectRow]) => {
                        setProductSelect(selectRow as any);
                    },
                }}
                isPagination={false}
            />
        </Modal>
    );
};

function OrderCreateDiscountModal({
    open,
    onClose,
}: IOrderCreateDiscountModal) {
    const { orderForm } = useContext(OrderCreateContext);
    const { vouchers, customer, productsWorksheet } = orderForm?.values ?? {};
    const [voucherConfirm, setVoucherConfirm] = useState<any>();
    const [openValidateVoucher, setOpenValidateVoucher] = useState<{
        open: boolean;
        validateVoucher: IVoucher | null;
    }>({ open: false, validateVoucher: null });
    const appliedVoucherProducts = useAppliedVouchersProductList(
        {
            products: productsWorksheet || [],
            vouchers: vouchers || [],
        },
        [vouchers, productsWorksheet]
    );

    const discountForm = useFormik<any>({
        initialValues: {
            amountType: "percentage",
            discountType: DISCOUNT_TYPE.MANUAL,
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values: any) => {
            onApplyDiscount(values);
        },
    });

    const formValues = discountForm?.values;

    const onApplyDiscount = (discount: any) => {
        switch (discount.discountType) {
            case DISCOUNT_TYPE.VOUCHER:
                onApplyVoucher(discount);
                break;
            case DISCOUNT_TYPE.MANUAL:
                onApplyDiscountManual(discount);
                onClose();
                break;
            default:
        }
    };

    const onApplyDiscountManual = (discount: any) => {
        const result = [
            ...orderForm?.values.manualDiscounts,
            { id: StringUtils.getUniqueID(), ...discount },
        ];
        orderForm.setFieldValue("manualDiscounts", result);
    };

    const onApplyVoucher = (discount: any) => {
        const code = discount?.voucher;
        const allCodes = map(vouchers, (i) => i?.code);
        if (allCodes.includes(code)) {
            return Notifications.showError(Messages.codeIsAlreadyApplied);
        }
        return Progress.show(
            { method: VoucherAPI.checkVouchers, params: [[code]] },
            (res: any) => {
                const voucher: IVoucher = res?.[0];
                const voucherCustomerId = voucher?.ownership?.customer?.id;
                if (voucherCustomerId) {
                    if (voucherCustomerId !== customer?.id) {
                        return Notifications.showError(
                            Messages.voucherIsInvalid
                        );
                    }
                    return processVoucherAuthorization(voucher);
                }
                return processApplyVoucher(voucher);
            }
        );
    };

    const processApplyVoucher = (voucher: IVoucher) => {
        if (openValidateVoucher.open) {
            setOpenValidateVoucher({ open: false, validateVoucher: null });
        }
        const productVoucher = voucher?.product;
        discountForm.setFieldValue("voucher", "");
        switch (productVoucher?.productType) {
            case PRODUCT_TYPE.PREMIUM_SERVICE:
                applyPremiumVoucher(voucher);
                break;
            default:
                applyDefaultVoucher(voucher);
        }
    };

    const processVoucherAuthorization = (voucher: IVoucher) => {
        setOpenValidateVoucher({ open: true, validateVoucher: voucher });
    };

    /**
     * Premium voucher is special voucher that applicable to any service
     * Only apply voucher for products that loaded from worksheet
     * if has no products loaded from WS => alert voucher invalid
     * if theres many worksheet's products => show modal for user to select the product that they wanna apply
     * if there only one product => apply directly
     */
    const applyPremiumVoucher = (voucher: any) => {
        const { productsWorksheet } = orderForm.values;
        const productVoucher = voucher?.product;
        const wsProductsVoucher = filter(productsWorksheet, (item) =>
            includes(
                map(productVoucher?.premiumService ?? [], (item) => item.id),
                item.id
            )
        );
        if (wsProductsVoucher.length === 0) {
            Notifications.showError(Messages.voucherOnlyApllyWorksheet);
        } else if (wsProductsVoucher.length === 1) {
            applyVoucher(voucher, wsProductsVoucher?.[0]);
        } else {
            setVoucherConfirm({ products: wsProductsVoucher, voucher });
        }
    };

    /**
     * Default voucher is normal voucher that applicable to specific service
     * Only apply voucher for products that loaded from worksheet
     */
    const applyDefaultVoucher = (voucher: any) => {
        const productVoucher = voucher?.product;
        const { productsWorksheet } = orderForm.values;
        const wsProductVoucher = find(
            productsWorksheet,
            (item) => item.id === productVoucher?.id
        );

        if (!wsProductVoucher) {
            Notifications.showError(Messages.voucherOnlyApllyWorksheet);
            return;
        }

        applyVoucher(voucher, wsProductVoucher);
    };

    const applyVoucher = (voucher: IVoucher, productToApply: IProduct) => {
        const foundPro = appliedVoucherProducts?.[productToApply?.id] ?? {};
        if (
            !foundPro ||
            !foundPro?.quantity ||
            !(foundPro?.quantity > (foundPro?.usedQty || 0))
        ) {
            return Notifications.showError(Messages.errorData);
        }
        const applyVoucher = mapVoucherToVoucherOrderCreate(
            voucher,
            productToApply
        );
        const resArr = addIdAndAmountToListVoucher([applyVoucher]);
        const finalRes = resArr?.[0];
        if (finalRes) {
            const isAnonymous = isAnonymousVoucher(voucher);
            Object.assign(finalRes, { isAnonymous });
            orderForm.setFieldValue("vouchers", [...vouchers, finalRes]);
        }
        return onClose();
    };

    const renderInputContent = () => {
        switch (formValues.discountType) {
            case DISCOUNT_TYPE.VOUCHER:
                return renderInputVoucher();
            case DISCOUNT_TYPE.MANUAL:
                return renderInputManual();
            default:
                return <div />;
        }
    };

    const renderInputManual = () => {
        return (
            <div className="d-flex">
                {map(DISCOUNT_AMOUNT_TYPES, (item) => (
                    <Button
                        onClick={() =>
                            discountForm.setFieldValue("amountType", item.id)
                        }
                        variant={
                            formValues?.amountType === item.id
                                ? "standard"
                                : "outline"
                        }
                    >
                        {item.label}
                    </Button>
                ))}
                <InputText
                    className="w-100"
                    placeholder={Messages.pleaseInput}
                    value={formValues.amount}
                    onChange={(event) =>
                        discountForm.setFieldValue("amount", event.target.value)
                    }
                />
            </div>
        );
    };

    const renderInputVoucher = () => {
        return (
            <InputText
                placeholder={Messages.pleaseInput}
                value={formValues.voucher}
                onChange={(event) =>
                    discountForm.setFieldValue("voucher", event.target.value)
                }
            />
        );
    };

    return (
        <React.Fragment>
            <Modal
                open={open}
                onClose={onClose}
                title={Messages.addDiscountItem}
                onSave={() => discountForm.handleSubmit()}
                saveText={Messages.apply}
            >
                <div>
                    {/* <RadioGroup
                        numberOfColumns="2"
                        dataSource={DISCOUNT_TYPES}
                        value={formValues.discountType}
                        onChange={(value: any) =>
                            discountForm.setFieldValue("discountType", value)
                        }
                    /> */}
                    {renderInputContent()}
                    {!!voucherConfirm && (
                        <OrderConfirmVoucherModal
                            open={!!voucherConfirm}
                            onClose={() => setVoucherConfirm(false)}
                            products={voucherConfirm?.products}
                            onSave={(product: IProduct) => {
                                applyVoucher(voucherConfirm?.voucher, product);
                            }}
                        />
                    )}
                </div>
            </Modal>
            {openValidateVoucher.open && (
                <VoucherSelectDrawer
                    initScreen={VoucherSelectScreenType.APPLY_CODE}
                    open={openValidateVoucher.open}
                    onClose={() =>
                        setOpenValidateVoucher({
                            open: false,
                            validateVoucher: null,
                        })
                    }
                    customerId={customer?.id}
                    products={productsWorksheet}
                    onChange={(v = []) => {
                        if (!openValidateVoucher.validateVoucher) return;
                        processApplyVoucher(
                            openValidateVoucher.validateVoucher
                        );
                    }}
                    value={vouchers}
                />
            )}
        </React.Fragment>
    );
}

export default OrderCreateDiscountModal;
