import { Icon, Notifications, TimeUtils } from "d-react-components";
import moment from "moment";
import ClassNames from "classnames";
import { filter, includes, map, reduce } from "lodash";
import React, { useState } from "react";
import Image from "../../../../common/Image";
import InputTextQuantity, {
    IInputTextQuantity,
} from "../../../../common/input/InputTextQuantity";
import {
    getProductLocalizeInfo,
    IProduct,
} from "../../../../interfaces/product";
import {
    isPremiumService,
    IVoucherProduct,
} from "../../../../interfaces/voucher";
import Messages from "../../../../languages/Messages";

interface IVoucherProductItemShow {
    qtyLabel?: boolean;
    code?: boolean;
    remove?: boolean;
}
export interface IVoucherProductItemProps {
    voucherProduct: IVoucherProduct & { id?: string };
    inputQuantityProps?: Partial<IInputTextQuantity>;
    showQtyInput?: boolean;
    showPremiumServices?: boolean;
    onChange?: (voucher?: IVoucherProductItemProps["voucherProduct"]) => any;
    onRemove?: (voucher?: IVoucherProductItemProps["voucherProduct"]) => any;
    getQty?: (voucher?: IVoucherProductItemProps["voucherProduct"]) => any;
    serviceToApplyPremium?: Array<IProduct>;
    show?: IVoucherProductItemShow;
}

const VoucherExpiringView: React.FC<{ expiryDate?: any }> = ({
    expiryDate,
}) => {
    const isExpiring =
        expiryDate &&
        moment().isBefore(moment(expiryDate)) &&
        moment().diff(moment(expiryDate), "days") <= 7;

    if (!expiryDate) {
        return <div className="mt-1 text-gray">{Messages.noExpireDate}</div>;
    }
    return (
        <div className="flex-center-y">
            <Icon name={isExpiring ? "running_with_errors" : "query_builder"} />
            <div>
                {isExpiring
                    ? `${Messages.expiringOn} ${TimeUtils.convertMiliToDateTime(
                          expiryDate
                      )}`
                    : `${Messages.until}  ${TimeUtils.convertMiliToDateTime(
                          expiryDate
                      )}`}
            </div>
        </div>
    );
};

const DEFAULT_SHOW = {
    qtyLabel: true,
    code: false,
    remove: false,
};

const VoucherProductItem: React.FC<IVoucherProductItemProps> = ({
    voucherProduct,
    showQtyInput = false,
    showPremiumServices = false,
    inputQuantityProps = {},
    serviceToApplyPremium,
    onChange,
    onRemove,
    getQty = (item) => item?.voucherQuantity ?? 0,
    show = {},
}) => {
    const showView = { ...DEFAULT_SHOW, ...(show || {}) };
    const {
        product,
        voucherQuantity = 0,
        expiryDate,
        quantity,
        //@ts-ignore
        appliedCode,
    } = voucherProduct || {};
    const isPremium = isPremiumService(voucherProduct);
    const { premiumService } = product || {};
    const [expanding, setExpanding] = useState(true);

    const renderQty = () => {
        return (
            <div
                className={ClassNames(
                    "position-absolute top-0 end-0 bg-primary flex-center",
                    {
                        "bg-secondary": isPremiumService(voucherProduct),
                    }
                )}
                style={{ width: "35px", height: "35px" }}
            >
                <div className="text text-small width-15 height-15  text-center text-white">
                    x{voucherQuantity || 0}
                </div>
            </div>
        );
    };

    const renderRemove = () => {
        return (
            <div
                className={ClassNames(
                    "position-absolute top-0 end-0 bg-primary flex-center"
                )}
                style={{ width: "35px", height: "35px" }}
                onClick={() => onRemove && onRemove(voucherProduct)}
            >
                <Icon name="delete" color="white" />
            </div>
        );
    };

    const renderImage = () => {
        return (
            <Image
                src={product?.thumbnail?.thumbnail}
                className="image-square-medium"
            />
        );
    };

    const renderContent = () => {
        return (
            <div className="ml-3">
                {showView?.code && (
                    <div className="text text-primary font-weight-bold">
                        {appliedCode}
                    </div>
                )}
                <div>{getProductLocalizeInfo(product, "name")}</div>
                <VoucherExpiringView expiryDate={expiryDate} />
                {showQtyInput && (
                    <InputTextQuantity
                        className="mt-2 bg-red"
                        {...inputQuantityProps}
                        value={quantity || 0}
                        onChange={(v) => {
                            if (v > voucherQuantity) {
                                return Notifications.showError(Messages.error);
                            }
                            return (
                                onChange &&
                                onChange({ ...voucherProduct, quantity: v })
                            );
                        }}
                        min={0}
                    />
                )}
            </div>
        );
    };

    const renderButton = (
        <div
            className="flex-center-y position-absolute bg-primary p-2 rounded-pill hover-pointer"
            style={{ left: "50%", bottom: "-10px" }}
            onClick={() => {
                setExpanding(!expanding);
            }}
        >
            <Icon
                name={expanding ? "expand_less" : "expand_more"}
                color="white"
            />
        </div>
    );

    const content = (
        <div
            className={ClassNames(
                "p-3 mt-3 flex-center-y position-relative bg-white",
                {
                    shadow: true,
                }
            )}
        >
            {renderImage()}
            {renderContent()}
            {showView.remove && renderRemove()}
            {showView.qtyLabel && renderQty()}
            {isPremium && showPremiumServices && renderButton}
        </div>
    );

    if (isPremium && showPremiumServices) {
        const renderSubView = () => {
            if (!expanding) {
                return null;
            }

            const onChangeItemQty = (idToUpdate: string, v: number) => {
                const newList = map(premiumService, (i) =>
                    i?.id === idToUpdate ? { ...i, quantity: v } : i
                );
                const total = reduce(
                    newList,
                    (res = 0, i) => {
                        const q = i?.quantity || 0;
                        const result = res + q;
                        return result;
                    },
                    0
                );
                if (total > voucherQuantity) {
                    return Notifications.showError(Messages.error);
                }
                return (
                    onChange &&
                    onChange({
                        ...voucherProduct,
                        quantity: total,
                        product: {
                            ...(voucherProduct?.product ?? {}),
                            premiumService: [...newList],
                        },
                    })
                );
            };

            return (
                <React.Fragment>
                    {map(
                        filter(premiumService, (pre) =>
                            includes(
                                map(serviceToApplyPremium, (i) => i?.id),
                                pre?.id
                            )
                        ),
                        (premiumItem, index) => {
                            return (
                                <div
                                    className={ClassNames(
                                        "flex-center-y py-2 bg-primary-10 p-3",
                                        {
                                            "border border-top-1": true,
                                        }
                                    )}
                                >
                                    <Image
                                        src={premiumItem?.thumbnail?.thumbnail}
                                        className="image-square-small"
                                    />
                                    <div className="ml-3">
                                        <div>
                                            {getProductLocalizeInfo(
                                                premiumItem,
                                                "name"
                                            )}
                                        </div>
                                        <InputTextQuantity
                                            onChange={(v) =>
                                                onChangeItemQty(
                                                    premiumItem?.id,
                                                    v
                                                )
                                            }
                                            value={premiumItem?.quantity ?? 0}
                                            min={0}
                                        />
                                    </div>
                                </div>
                            );
                        }
                    )}
                </React.Fragment>
            );
        };

        return (
            <div className="shadow">
                {content}
                {renderSubView()}
            </div>
        );
    }

    return content;
};

export default VoucherProductItem;
