import {
    Button,
    Drawer,
    IDrawerProps,
    InputTextSearch,
    Notifications,
    StringUtils,
    TabBar,
} from "d-react-components";
import { filter, find, forEach, groupBy, map, slice } from "lodash";
import React, { Dispatch, useContext, useState } from "react";
import { IProduct } from "../../../../interfaces/product";
import {
    IVoucherOrderCreate,
    IVoucherProduct,
} from "../../../../interfaces/voucher";
import Messages from "../../../../languages/Messages";
import ProductNameView from "../../../product/common/ProductNameView";
import VoucherProductItem from "./VoucherProductItem";

export interface IVoucherAppliedDrawerProps extends Partial<IDrawerProps> {
    appliedProduct: IProduct & { totalQty: number };
    appliedVouchers: IVoucherOrderCreate[];
    onChange?: (listToRemove: IVoucherOrderCreate[]) => any;
}

export interface IVoucherProductOwnership extends IVoucherProduct {
    appliedList?: IVoucherAppliedDrawerProps["appliedVouchers"];
    appliedCode?: string | null;
    isRemove?: boolean;
    id?: string;
}

export interface IVoucherAppliedState extends IVoucherAppliedDrawerProps {
    ownershipVouchers: Array<IVoucherProductOwnership>;
    anonymousVouchers: Array<IVoucherProductOwnership>;
    setOwnershipVouchers: Dispatch<Array<IVoucherProductOwnership>>;
    setAnonymousVouchers: Dispatch<Array<IVoucherProductOwnership>>;
}

const voucherAppliedState: IVoucherAppliedState = {} as any;
export const VoucherAppliedContext = React.createContext(voucherAppliedState);

const getInitOwnershipVouchers = (voucherList: IVoucherOrderCreate[]) => {
    const filterList = filter(voucherList, (i) => !i?.isAnonymous);
    const res: Array<IVoucherProductOwnership> = [];
    if (filterList && filterList?.length > 0) {
        const grouped = groupBy(filterList, (i) => i?.product?.id);
        forEach(grouped, (items, key) => {
            res.push({
                product: items?.[0]?.product,
                quantity: items?.length ?? 0,
                appliedList: items,
                voucherQuantity: items?.length ?? 0,
                id: StringUtils.getUniqueID(),
            });
        });
    }
    return res;
};

const getInitAnonymousVouchers = (voucherList: IVoucherOrderCreate[]) => {
    const filterList = filter(voucherList, (i) => !!i?.isAnonymous);
    const res: Array<IVoucherProductOwnership> = [];
    if (filterList && filterList?.length > 0) {
        forEach(filterList, (item, index) => {
            res.push({
                product: item?.product,
                quantity: 1,
                appliedList: [item],
                appliedCode: item?.code,
                voucherQuantity: 1,
            });
        });
    }
    return res;
};

const VoucherAppliedOwnerShip: React.FC<any> = () => {
    const { appliedVouchers, ownershipVouchers, setOwnershipVouchers } =
        useContext(VoucherAppliedContext);

    const onChangeItem = (itemToChange: any) => {
        const clone = map(ownershipVouchers, (i) =>
            i?.product?.id === itemToChange?.product?.id ? itemToChange : i
        );
        setOwnershipVouchers(clone);
    };

    return (
        <React.Fragment>
            {map(ownershipVouchers, (item, index) => {
                return (
                    <VoucherProductItem
                        key={`${item?.product?.id}_${index}`}
                        show={{ qtyLabel: false }}
                        showQtyInput
                        voucherProduct={item}
                        inputQuantityProps={{
                            max: item?.voucherQuantity ?? 0,
                            min: 0,
                        }}
                        onChange={(item) => {
                            onChangeItem(item);
                        }}
                    />
                );
            })}
        </React.Fragment>
    );
};

const VoucherAppliedAnonymous: React.FC<any> = () => {
    const { appliedVouchers, anonymousVouchers, setAnonymousVouchers } =
        useContext(VoucherAppliedContext);
    const [textSearch, setTextSearch] = useState<string | null>(null);

    return (
        <React.Fragment>
            <InputTextSearch
                className="bg-white mt-3"
                placeholder={Messages.search}
                onChange={(e: any) => setTextSearch(e?.target?.value)}
            />
            {map(anonymousVouchers, (item, index) => {
                if (item?.isRemove) {
                    return null;
                }
                let showItem = true;
                if (textSearch) {
                    const itemCode = item?.appliedCode ?? "";
                    if (
                        itemCode
                            ?.toLowerCase?.()
                            .indexOf(textSearch?.toLowerCase?.()) === -1
                    ) {
                        showItem = false;
                    }
                }
                if (!showItem) {
                    return null;
                }
                return (
                    <VoucherProductItem
                        key={`${item?.product?.id}_${index}`}
                        show={{ qtyLabel: false, code: true, remove: true }}
                        voucherProduct={item}
                        onRemove={(itemToRemove) => {
                            const removedList = map(anonymousVouchers, (i) =>
                                i?.id === itemToRemove?.id
                                    ? { ...i, isRemove: true }
                                    : i
                            );
                            setAnonymousVouchers(removedList);
                        }}
                    />
                );
            })}
        </React.Fragment>
    );
};

const VOUCHER_APPLIED_TABS = [
    {
        id: "ownership",
        label: "ownership",
        component: <VoucherAppliedOwnerShip />,
    },
    {
        id: "anonymous",
        label: "anonymous",
        component: <VoucherAppliedAnonymous />,
    },
];

const VoucherAppliedDrawer: React.FC<IVoucherAppliedDrawerProps> = ({
    appliedProduct,
    appliedVouchers,
    onChange,
    open = false,
    onClose,
}) => {
    const initOwnershipVoucher = getInitOwnershipVouchers(appliedVouchers);
    const initAnonymousVoucher = getInitAnonymousVouchers(appliedVouchers);
    const [ownershipVouchers, setOwnershipVouchers] =
        useState<IVoucherProductOwnership[]>(initOwnershipVoucher);
    const [anonymousVouchers, setAnonymousVouchers] =
        useState<IVoucherProductOwnership[]>(initAnonymousVoucher);
    const [tabSelected, setTabSelected] = useState<any>(
        VOUCHER_APPLIED_TABS[0]
    );

    const onApplyChange = () => {
        let listToRemove: IVoucherOrderCreate[] = [];
        forEach(ownershipVouchers, (item) => {
            const {
                voucherQuantity = 0,
                quantity = 0,
                appliedList,
            } = item || {};
            const removeQty = voucherQuantity - quantity;
            if (removeQty > 0) {
                const removeItems = slice(appliedList, 0, removeQty);
                listToRemove = [...listToRemove, ...removeItems];
            }
        });

        forEach(anonymousVouchers, (item) => {
            if (item?.isRemove) {
                if (item?.appliedList?.[0]) {
                    listToRemove.push(item?.appliedList?.[0]);
                }
            }
        });
        onChange && onChange(listToRemove);
        onClose && onClose(null as any);
    };

    const renderFooterDrawer = () => {
        return (
            <div className="w-100 d-flex p-3">
                <div className="flex-fill">
                    <Button
                        content={Messages.cancel}
                        className=""
                        variant="outline"
                        onClick={() => {
                            return onClose && onClose(null as any);
                        }}
                    />
                </div>
                <Button
                    content={Messages.apply}
                    className=""
                    onClick={() => {
                        onApplyChange();
                    }}
                    // disabled={!enableSaveVoucherButton}
                />
            </div>
        );
    };

    return (
        <VoucherAppliedContext.Provider
            value={{
                appliedProduct,
                appliedVouchers,
                ownershipVouchers,
                setOwnershipVouchers,
                anonymousVouchers,
                setAnonymousVouchers,
            }}
        >
            <Drawer
                size="x-large"
                open={open}
                onClose={onClose}
                footer={renderFooterDrawer()}
            >
                <div className="flex-center-y w-100 border-bottom py-3">
                    <div className="h4 text-primary w-100 text-center">
                        {Messages.appliedVoucher}
                    </div>
                </div>
                <div className="p-3 bg-muted h-100">
                    <ProductNameView
                        className="flex-row"
                        classNameLabel="text-white"
                        classNameContent="bg-primary w-100 text-white mx-0 p-3"
                        styleImage={{ height: "auto", width: "100px" }}
                        product={appliedProduct}
                        subContent={
                            <React.Fragment>
                                <div className="flex-center-y">
                                    <small>{Messages.serviceQty}: </small>
                                    <div className="ml-2">
                                        {appliedProduct?.totalQty}
                                    </div>
                                </div>
                                <div className="flex-center-y">
                                    <small>{Messages.appliedVoucherQty}:</small>
                                    <div className="ml-2">
                                        {appliedVouchers?.length}
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                    />
                    <TabBar
                        className="mt-3 w-100"
                        dataSource={VOUCHER_APPLIED_TABS}
                        onChange={setTabSelected}
                        value={tabSelected}
                        getLabel={(i) =>
                            Messages[i?.label as keyof typeof Messages]
                        }
                    />
                    {tabSelected.component}
                </div>
            </Drawer>
        </VoucherAppliedContext.Provider>
    );
};

export default VoucherAppliedDrawer;
