/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-return-assign */
import { Button, Notifications, StringUtils } from "d-react-components";
import { find, isEmpty, map } from "lodash";
import React, { useContext, useState } from "react";
import { OrderCreateContext } from "../../../../context/order";
import {
    isPremiumService,
    IVoucherOrderCreate,
} from "../../../../interfaces/voucher";
import Messages from "../../../../languages/Messages";
import VoucherSelectDrawer from "./VoucherSelectDrawer";

export interface IOrderCreateSelectVoucherButtonProps {
    [key: string]: any;
}

export const addIdAndAmountToListVoucher = (
    listToAdd: Array<IVoucherOrderCreate>,
    isInvoice?: boolean
): Array<IVoucherOrderCreate> => {
    const transList = map(listToAdd, (voucher) => {
        const { product, appliedFor } = voucher;
        const isPremium = isPremiumService(voucher);
        let amount = 0;
        /**
         * Premium voucher is special voucher that applicable to any service
         * Only apply voucher for products that loaded from worksheet
         * if has no products loaded from WS => alert voucher invalid
         * if theres many worksheet's products => show modal for user to select the product that they wanna apply
         * if there only one product => apply directly
         */
        if (isPremium) {
            const premiumList = voucher?.product?.premiumService ?? [];
            const foundItem = find(premiumList, (i) => i?.id === appliedFor);
            if (foundItem) {
                amount = foundItem?.salePrice;
                if (isInvoice) {
                    amount *= 1.07;
                }
            }
            /**
             * Default voucher is normal voucher that applicable to specific service
             * Only apply voucher for products that loaded from worksheet
             */
        } else {
            amount = product?.salePrice;
            if (isInvoice) {
                amount *= 1.07;
            }
        }

        const newVoucher = {
            id: StringUtils.getUniqueID(),
            isVoucher: true,
            amount,
            ...voucher,
        };
        return newVoucher;
    });
    return transList;
};

const OrderCreateSelectVoucherButton: React.FC<IOrderCreateSelectVoucherButtonProps> =
    () => {
        const { orderForm } = useContext(OrderCreateContext);
        const { values, setFieldValue } = orderForm || {};
        const { customer, productsWorksheet, vouchers } = values ?? {};
        const [openSelectVoucher, setOpenSelectVoucher] = useState(false);

        const handleUpdateVoucher = (
            applyVouchers: Array<IVoucherOrderCreate>
        ) => {
            const transList = addIdAndAmountToListVoucher(
                applyVouchers,
                orderForm?.values?.isInvoice
            );
            setFieldValue("vouchers", [...transList, ...vouchers]);
        };

        return (
            <React.Fragment>
                <Button
                    onClick={() => {
                        if (isEmpty(productsWorksheet)) {
                            return Notifications.showError(
                                Messages.youNeedAddAtLeastOneProduct
                            );
                        }
                        setOpenSelectVoucher(true);
                    }}
                    variant="outline"
                    className="mt-3"
                >
                    {Messages.selectVoucher}
                </Button>
                {openSelectVoucher && (
                    <VoucherSelectDrawer
                        open={openSelectVoucher}
                        onClose={() => setOpenSelectVoucher(false)}
                        customerId={customer?.id}
                        products={productsWorksheet}
                        onChange={(v = []) => handleUpdateVoucher(v)}
                        value={vouchers}
                    />
                )}
            </React.Fragment>
        );
    };

export default OrderCreateSelectVoucherButton;
