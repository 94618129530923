import { PRODUCT_TYPE } from "../constant/product";
import { ICustomer } from "./customer";
import { IProduct } from "./product";
import { IUser } from "./user";

export interface IVoucherOwnership {
    status: string;
    customer: ICustomer;
    remark: string;
}

export interface IVoucher {
    id: string;
    code: string;
    remark: string;
    status: string;
    effectiveDate: string;
    expiryDate: string;
    createBy: IUser;
    createdAt: string;
    product: IProduct;
    ownership: IVoucherOwnership;
    orderId: String;
    usageState: {
        status: string;
        usedAt: string;
        // order: {
        //     id: string;
        //     orderNo: string;
        // };
        worksheet: {
            id: string;
            worksheetNo: string;
        };
    };
    isPremiumVoucher: boolean;
    priceFromOrder: number;
}

export interface IVoucherProduct {
    product: IProduct;
    voucherQuantity?: number;
    quantity?: number;
    expiryDate?: any;
    voucherCodes?: string[];
    quantityLeft?: number;
    codesLeft?: string[];
}

export interface IVoucherOrderCreate {
    id?: string;
    isVoucher?: boolean;
    amount?: any;
    code?: string | null;
    product: IProduct;
    appliedFor?: string;
    isAnonymous?: boolean;
    voucherCodes?: string[];
}

export interface IVoucherWorksheetDetail extends IVoucher {
    appliedFor?: string | null;
    appliedProduct?: IProduct;
    isAnonymous?: boolean;
}

export const mapVoucherToServer = (voucher: any) => {
    const { products, code, remark, effectiveDate, expiryDate, ownerShip } =
        voucher;
    return {
        productId: products?.[0]?.id,
        code,
        remark,
        effectiveDate,
        expiryDate,
        ownershipId: ownerShip?.id,
    };
};

export const mapBulkVoucherToServer = (voucher: any) => {
    const {
        products,
        quantity,
        remark,
        effectiveDate,
        expiryDate,
        ownerShip,
        prefix,
        suffix,
    } = voucher;
    return {
        productId: products?.[0]?.id,
        quantity,
        remark,
        effectiveDate,
        expiryDate,
        ownershipId: ownerShip?.id,
        prefix,
        suffix,
    };
};

export const mapVoucherFromServer = (voucher: any) => {
    const { product } = voucher;
    return {
        ...voucher,
        isPremiumVoucher: product?.productType === PRODUCT_TYPE.PREMIUM_SERVICE,
    };
};

export const mapVoucherToVoucherOrderCreate = (
    voucher: IVoucher,
    pro: IProduct
): IVoucherOrderCreate => {
    const { product, code } = voucher;
    return {
        code,
        appliedFor: pro?.id,
        product,
    };
};

export const isPremiumService = (service: IVoucherProduct) => {
    return service?.product?.productType === PRODUCT_TYPE.PREMIUM_SERVICE;
};

export const isAnonymousVoucher = (voucher: IVoucher) => {
    return !voucher?.ownership?.customer?.id;
};
