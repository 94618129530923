/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { Button, StringUtils } from "d-react-components";
import { filter, find, isEmpty, map, reduce } from "lodash";
import React, { useContext, useState } from "react";
import { OrderCreateContext } from "../../../../context/order";
import { IOrderVoucherDisplay } from "../../../../interfaces/order";
import { getProductLocalizeInfo } from "../../../../interfaces/product";
import { IVoucherOrderCreate } from "../../../../interfaces/voucher";
import Messages from "../../../../languages/Messages";
import VoucherAppliedDrawer from "./VoucherAppliedDrawer";

export interface IVoucherAppliedRowProps {
    data: IOrderVoucherDisplay;
}

const VoucherAppliedRow: React.FC<IVoucherAppliedRowProps> = ({ data }) => {
    const { orderForm } = useContext(OrderCreateContext);
    const [openAppliedVoucher, setOpenAppliedVoucher] = useState(false);
    const { productsWorksheet, vouchers } = orderForm?.values ?? {};
    const { title, total, numberOfVouchers, appliedFor, appliedVouchers } =
        data || {};
    const appliedProduct = find(productsWorksheet, (i) => i?.id === appliedFor);
    const totalQty = reduce(
        productsWorksheet,
        (res = 0, item) =>
            item?.id === appliedProduct?.id
                ? (res += item?.quantity ?? 0)
                : res,
        0
    );

    const onCallBackRemoveVouchers = (listToRemove: IVoucherOrderCreate[]) => {
        if (!isEmpty(listToRemove)) {
            const idsToRemove = map(listToRemove, (i) => i?.id);
            const removedList = filter(
                vouchers,
                (i) => !idsToRemove.includes(i?.id)
            );
            orderForm.setFieldValue("vouchers", removedList);
        }
    };

    return (
        <React.Fragment>
            <div className="d-flex flex-row justify-content-between border-bottom py-2">
                <div>
                    <div
                        className="d-flex flex-row align-items-center hover-pointer"
                        onClick={() => {}}
                    >
                        <div>{`${title}`}</div>
                        <div
                            className="bg-primary rounded-1 px-3 py-1 ml-2 text-white"
                            onClick={() => {
                                setOpenAppliedVoucher(true);
                            }}
                        >
                            {numberOfVouchers}
                        </div>
                    </div>
                    <div className="text-primary">
                        {`( ${getProductLocalizeInfo(
                            appliedProduct,
                            "name"
                        )} )`}
                    </div>

                    <Button
                        content={Messages.remove}
                        color="secondary"
                        variant="trans"
                        size="x-small"
                        className="pl-0"
                        onClick={() => {
                            const removedVoucher = filter(
                                vouchers,
                                (i) => i?.appliedFor !== appliedFor
                            );
                            orderForm.setFieldValue("vouchers", removedVoucher);
                        }}
                    />
                </div>
                <div className="text-success">
                    {`-${StringUtils.moneyThaiFormatFixed2(total as any)}`}
                </div>
            </div>

            {openAppliedVoucher && (
                <VoucherAppliedDrawer
                    open={openAppliedVoucher}
                    onClose={() => {
                        setOpenAppliedVoucher(false);
                    }}
                    appliedProduct={{
                        ...appliedProduct,
                        totalQty,
                    }}
                    appliedVouchers={appliedVouchers || []}
                    onChange={onCallBackRemoveVouchers}
                />
            )}
        </React.Fragment>
    );
};

export default VoucherAppliedRow;
