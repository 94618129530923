import { Switch, Tooltip } from "antd";
import {
    AwesomeTableComponent,
    Button,
    IColumnsProps,
    Icon,
    InputText,
    ObjectUtils,
    Select,
    ViewCollapse,
} from "d-react-components";
import { find, map } from "lodash";
import React, { useContext } from "react";
import { PRODUCT_TYPE, PRODUCT_TYPES } from "../../../constant/product";
import { ProductDetailContext } from "../../../context/product";
import Messages from "../../../languages/Messages";
import ProductSearchButton from "../common/ProductSearchButton";
import ProductNameView from "../common/ProductNameView";
import InputTextQuantity from "../../../common/input/InputTextQuantity";
import { YES_NO_OPTIONS } from "../../../constant/app";

const ProductTypePremiumService = () => {
    const { productForm } = useContext(ProductDetailContext);
    const productFormValue = productForm?.values;
    const columns: IColumnsProps = [
        {
            title: Messages.itemName,
            dataIndex: "",
            render: (product: any) => <ProductNameView product={product} />,
            width: 400,
        },
        // {
        //     title: Messages.giftVoucherConversion,
        //     dataIndex: "voucherConversion",
        //     render: (quantity: any, item) => (
        //         <InputTextQuantity
        //             value={quantity ?? 1}
        //             onChange={(quantity) =>
        //                 onUpdate({
        //                     ...item,
        //                     voucherConversion: quantity,
        //                 })
        //             }
        //             min={1}
        //         />
        //     ),
        //     width: 400,
        // },
        {
            title: Messages.action,
            dataIndex: "",
            render: (product) => (
                <Button
                    iconName="delete"
                    variant="trans"
                    onClick={() => onRemove(product?.id)}
                />
            ),
        },
    ];

    const onUpdate = (newProduct: any) => {
        const services = ObjectUtils.updateArrayById(
            productFormValue?.premiumService ?? [],
            newProduct
        );
        productForm.setFieldValue("premiumService", services);
    };

    const onRemove = (id: string) => {
        const services = ObjectUtils.removeArrayById(
            productFormValue?.premiumService ?? [],
            id
        );
        productForm.setFieldValue("premiumService", services);
    };

    const onAdd = (products: any[]) => {
        const services = map(products, (item) => {
            const existedProduct = find(
                productFormValue.premiumService,
                (preService) => preService.id === item.id
            );

            if (existedProduct) {
                return existedProduct;
            }
            return item;
            // return { ...item, voucherConversion: 1 };
        });
        productForm.setFieldValue("premiumService", services);
    };

    return (
        <div className="mt-3">
            <div className="d-flex justify-content-between align-items-center">
                <label>{Messages.premiumService}</label>
                <ProductSearchButton
                    onSave={onAdd}
                    defaultValue={productFormValue?.premiumService ?? []}
                    isHideAvaQtyCurrentWH
                    isHideOnHoldQtyCurrentWH
                    filterSource={{
                        productType: [PRODUCT_TYPE.SERVICE_PRODUCT],
                    }}
                >
                    {Messages.addItem}
                </ProductSearchButton>
            </div>
            <AwesomeTableComponent
                columns={columns}
                className="mt-3"
                dataSource={productFormValue?.premiumService ?? []}
            />
        </div>
    );
};

/**
 * Product group include the simple products only, not include service product
 */
const ProductTypeGroup = () => {
    const { productForm } = useContext(ProductDetailContext);
    const productFormValue = productForm?.values;
    const columns: IColumnsProps = [
        {
            title: Messages.itemName,
            dataIndex: "",
            render: (product: any) => <ProductNameView product={product} />,
            width: 400,
        },
        {
            title: Messages.action,
            dataIndex: "",
            render: (product) => (
                <Button
                    iconName="delete"
                    variant="trans"
                    onClick={() => onRemove(product?.id)}
                />
            ),
            width: 100,
        },
    ];

    const onRemove = (id: string) => {
        const services = ObjectUtils.removeArrayById(
            productFormValue?.groupProducts ?? [],
            id
        );
        productForm.setFieldValue("groupProducts", services);
    };

    return (
        <div className="mt-3">
            <div className="d-flex justify-content-between align-items-center">
                <label>{Messages.groupProduct}</label>
                <ProductSearchButton
                    onSave={(values) =>
                        productForm.setFieldValue("groupProducts", values)
                    }
                    defaultValue={productFormValue?.groupProducts ?? []}
                    isHideAvaQtyCurrentWH
                    isHideOnHoldQtyCurrentWH
                    filterSource={{
                        productType: [PRODUCT_TYPE.DEFAULT_PRODUCT],
                    }}
                >
                    {Messages.addItem}
                </ProductSearchButton>
            </div>
            <AwesomeTableComponent
                columns={columns}
                className="mt-3"
                dataSource={productFormValue?.groupProducts ?? []}
                isPagination={false}
            />
        </div>
    );
};

const ProductCrudType = () => {
    const { productForm, categoryServices } = useContext(ProductDetailContext);
    const productFormValue = productForm?.values;
    const categoryService = find(
        categoryServices,
        (item) => item.id === productFormValue?.serviceCategoryId
    );

    const renderProductTypeConfig = () => {
        switch (productFormValue?.productType) {
            case PRODUCT_TYPE.SERVICE_PRODUCT:
                return renderProductServiceConfig();
            case PRODUCT_TYPE.PREMIUM_SERVICE:
                return <ProductTypePremiumService />;
            case PRODUCT_TYPE.GROUP_PRODUCT:
                return <ProductTypeGroup />;
            default:
                return <div />;
        }
    };

    const renderProductServiceConfig = () => (
        <div>
            <Select
                value={productFormValue?.serviceCategoryId}
                onChange={(value) =>
                    productForm.setFieldValue("serviceCategoryId", value)
                }
                dataSource={categoryServices}
                error={productForm.errors.serviceCategoryId}
                label={Messages.serviceCategory}
                className="mt-3"
            />
            <Select
                value={productFormValue?.allowCombineVoucher}
                onChange={(value) =>
                    productForm.setFieldValue("allowCombineVoucher", value)
                }
                dataSource={YES_NO_OPTIONS}
                error={productForm.errors.allowCombineVoucher}
                label={Messages.allowCombineVoucherConversion}
                className="mt-3"
            />
            {categoryService?.seatConfiguration && (
                <InputText
                    className="mt-3"
                    label={Messages.numberOfSeat}
                    value={productFormValue?.numberOfSeat}
                    onChange={(event) =>
                        productForm.setFieldValue(
                            "numberOfSeat",
                            event.target.value
                        )
                    }
                />
            )}
        </div>
    );

    return (
        <ViewCollapse className="mt-3" label={Messages.productType}>
            {map(PRODUCT_TYPES, (type) => (
                <div className="d-flex align-items-center mt-3">
                    <Switch
                        checked={productFormValue?.productType === type?.id}
                        onChange={() =>
                            productForm.setFieldValue("productType", type?.id)
                        }
                    />
                    <text className="ml-3">
                        {(Messages as any)[type.label]}
                    </text>
                    {type.tooltip && (
                        <Tooltip
                            title={(Messages as any)[type.tooltip]}
                            className="ml-1"
                        >
                            <Icon name="info" />
                        </Tooltip>
                    )}
                </div>
            ))}
            <div className="mt-3">{renderProductTypeConfig()}</div>
        </ViewCollapse>
    );
};

export default ProductCrudType;
